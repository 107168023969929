<template>
    <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
    <section class="ecommerce-application">
        <!-- Chat Content -->
        <b-row>
            <b-col cols="12" md="4">
                <b-row>
                    <b-form @submit.prevent="sendAudio" class="w-100">
                        <b-col cols="12">
                            <b-form-group class="">
                                <b-input name="query" :disabled="loading" v-model="query" placeholder="Title" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group :label="$t('Audio File')" label-for="file">
                                <b-form-file 
                                    v-model="audio" 
                                    placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Drop file here..."
                                    accept=".mp3,.mp4,.mpeg,.mpga,.m4a,.wav,.webm"
                                    class="mb-2"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-button class="mb-2" type="submit" variant="primary" :disabled="isEmpty(query) || loading">
                                Generate
                            </b-button>
                        </b-col>
                    </b-form>
                </b-row>
            </b-col>
            <b-col cols="12" md="8">
                <small v-if="isEmpty(response)">Your output will appear here.</small>
                <template v-else>
                    <b-textarea rows="15" :value="response.text" :id="`_responsemsg`" :readonly="true" />
                    <b-img
                        class="float-right mt-1"
                        :title="'Copy to Clipboard'"
                        @click="copyToClipBoard(`_responsemsg`)"
                        :src="require('@/assets/images/app-icons/icons8-copy-48.png')"
                        width="24"
                    />
                </template>
            </b-col>
            <b-col cols="12" class="mt-3 w-100">
                <good-data-table ref="savedS2TTable" :columns="fields" :no-serial="true"
                        :total-column="'response.responseData.total'" :data-column="'response.responseData.data'"
                        :api-endpoint="'/ai/saved-s2t'"
                        @add-new-info="toggelModal = true" :hideExportBtns="true" />
            </b-col>
            <b-textarea id="copyInput" :value="copyInputValue" style="height: 1px;width:1px;border: none; background: transparent;color: transparent;overflow: hidden;resize: none;padding: 0 0 0 0;" readonly />
        </b-row>
    </section>
</template>
  
<script>
import { mapActions } from 'vuex'
import { isEmpty } from '@/utilities'
import { showErrorNotification, showLoader, showSuccessNotification, hideLoader, showDangerNotification } from '@/@core/comp-functions/ui/app'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
export default {
    name: 'AIS2T',
    components: {
        GoodDataTable
    },
    props: {

    },

    data: () => ({
        loading: false,
        query: null,
        audio: null,
        response: null,
        copyInputValue: null,
    }),

    computed: {
        isMobileView: {
            get(){
                return this.$store.getters['app/windowSize'] <= 767
            }
        },
        fields: {
            get() {
                return [
                    {
                        field: 'query',
                        label: 'Title',
                        align: 'center',
                    },
                    {
                        field: 'short_text',
                        label: 'Text',
                        align: 'center',
                    },
                    {
                        field: 'audio',
                        label: 'Audio',
                        align: 'center',
                        useHtml: true,
                        renderer: props => {
                            return `<audio src="${jwtDefaultConfig.baseEndpoint}${props.audio}" controls />`;
                        },
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        align: 'center',
                        sortable: false,
                        type: 'dropdown',
                        actions: [
                            {
                                isIcon: true,
                                iconHtml: this.$helpers.getIcons('copy'),
                                text: 'Copy Text',
                                action: props => {
                                    this._copyText(props.text)
                                },
                            },
                            {
                                isIcon: true,
                                iconHtml: this.$helpers.getIcons('delete'),
                                text: 'Delete',
                                action: props => {
                                    this.$swal({
                                        title: this.$t('Do you want to delete this file?'),
                                        iconHtml: this.$helpers.swalIcon('alert.png'),
                                        showCancelButton: true,
                                        confirmButtonText: this.$t('Yes'),
                                        cancelButtonText: this.$t('No'),
                                        customClass: {
                                            confirmButton: 'btn btn-success',
                                            cancelButton: 'btn btn-danger ml-1',
                                            icon: 'border-0',
                                        },
                                        buttonsStyling: false,
                                    }).then(result => {
                                        if (result.value) {
                                            this.deleteItem(props)
                                        }
                                    })
                                },
                            },
                        ],
                    },
                ].filter(i => {
                    if(this.isMobileView){
                        return i.field != 'audio'
                    }
                    return true
                })
            }
        }
    },
    mounted() {
    },
    methods: {
        isEmpty,
        ...mapActions('ai', [
            'aiSpeechToText',
            'deleteSavedS2T',
        ]),
        sendAudio(){
            this.errors = []
            if (isEmpty(this.audio)) {
                showErrorNotification(this, "Select the audio file first!")
                return false;
            }
            this.loading = true
            showLoader()
            if(!isEmpty(this.query)){
                this.loading = true
                const payLoad = {
                    query: this.query,
                    audio: this.audio,
                }
                this.aiSpeechToText(payLoad).then(r => {
                    if (r._statusCode === 200) {
                        this.$refs.savedS2TTable.loadItems()
                        this.response = r.responseData
                    }else {
                        showErrorNotification(this, r.message || "Something went wrong!")
                    }
                    this.loading = false
                    hideLoader()
                }).catch((error) => {
                    showErrorNotification(this, "Something went wrong!")
                    this.loading = false
                    hideLoader()
                });
            }
        },
        async _copyText(text){
            this.copyInputValue = text
            setTimeout(() => {
                let copyInput = document.querySelector('#copyInput');
                copyInput.select();
                try {
                    if(document.execCommand('copy')){
                        showSuccessNotification(this, 'Copied to clipboard');
                    }else{
                        showErrorNotification(this, "Failed to copy");
                    }
                } catch (err) {
                    showErrorNotification(this, "Failed to copy");
                }
                window.getSelection().removeAllRanges()
            }, 100);
        },
        copyToClipBoard(copy_input_id) {
            let copyInput = document.querySelector('#'+copy_input_id);
            copyInput.select();
            try {
                if(document.execCommand('copy')){
                    showSuccessNotification(this, 'Copied to clipboard');
                }else{
                    showErrorNotification(this, "Failed to copy");
                }
            } catch (err) {
                showErrorNotification(this, "Failed to copy");
            }
            window.getSelection().removeAllRanges()
        },
        async deleteItem(s2t){
            this.loading = true
            showLoader()
            this.deleteSavedS2T(s2t.id).then(r => {
                if (r === true) {
                    this.$refs.savedS2TTable.loadItems()
                }else {
                    showErrorNotification(this, r || "Something went wrong!")
                }
                this.loading = false
                hideLoader()
            }).catch(e => {
                this.loading = false
                hideLoader()
            })
        }
    },

}
</script>